"use client";

import Icon from "@atoms/icon/Icon";
import { IconsNames } from "@icons/allIcons";
import { cn } from "@utils/tailwind";
import { AnimatePresence, motion } from "framer-motion";
import {
  ComponentPropsWithoutRef,
  forwardRef,
  useEffect,
  useState,
} from "react";

type Props = ComponentPropsWithoutRef<"button"> & {
  copiedText: string;
  icon: IconsNames;
  iconClassName?: string;
};

export const CopyButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {
    copiedText,
    icon,
    iconClassName,
    onClick,
    children,
    className,
    ...rest
  } = props;

  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const id = setTimeout(() => {
      setCopied(false);
    }, 1500);
    return () => clearTimeout(id);
  }, [copied]);

  const copy = () => {
    void navigator.clipboard.writeText(copiedText);
    setCopied(true);
  };

  return (
    <button
      onClick={(e) => {
        copy();
        if (onClick) onClick(e);
      }}
      className={cn(
        "flex min-h-6 items-center gap-2 self-end text-content-primary-on-light opacity-50 transition-opacity hover:opacity-100",
        className
      )}
      ref={ref}
      {...rest}
    >
      <span className="relative size-6">
        <AnimatePresence>
          {copied && (
            <motion.span
              key="thick"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              className="absolute inset-0"
            >
              <Icon name="Thick" className="mt-0.5 size-4" />
            </motion.span>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {!copied && (
            <motion.span
              key="copy"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              className="absolute inset-0"
            >
              <Icon name={icon} className={iconClassName} />
            </motion.span>
          )}
        </AnimatePresence>
      </span>
      {children}
    </button>
  );
});

CopyButton.displayName = "CopyButton";
